import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, hashHistory } from "react-router";

import configureStore from "./store";
import Results from "./containers/Results.jsx";
import AppContainer from "./containers/AppContainer.jsx";

const store = configureStore();

const handleRedirect = (nextState, replace) => {
  replace("/");
};

ReactDOM.render(
  <Provider store={store}>
    <Router history={hashHistory}>
      <Route path="/" component={AppContainer} />
      <Route path="/results" component={Results} />
      <Route path="*" onEnter={handleRedirect} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
