import * as Actions from './../constants/actions';

const initialState = {
    numberOfPassengers: 3,

    from: {
        latitude: null,
        longitude: null,
    },
    to: {
        latitude: null,
        longitude: null,
    },
};

/* eslint indent: 0 */
export default function reducer(state = initialState, action = {}) {
    if (action.type === Actions.INCREASE_NUMBER_OF_PASSENGERS) {
        return {
            ...state,
            numberOfPassengers: state.numberOfPassengers < 99 ? state.numberOfPassengers + 1 : state.numberOfPassengers,
        };
    }

    if (action.type === Actions.DECREASE_NUMBER_OF_PASSENGERS) {
        return {
            ...state,
            numberOfPassengers: state.numberOfPassengers > 1 ? state.numberOfPassengers - 1 : state.numberOfPassengers,
        };
    }

    if (action.type === Actions.UPDATE_FROM_LOCATION) {
        return {
            ...state,
            from: action.from,
        };
    }

    if (action.type === Actions.UPDATE_TO_LOCATION) {
        return {
            ...state,
            to: action.to,
        };
    }

    return state;
}
