/* eslint no-undef: 0 */
export default function ajaxHandler(method, url, jsonParams, token) {
    const promise = new Promise((resolve, reject) => {
        const client = new XMLHttpRequest();
        const uri = url;

        let jsonAsString = '';
        client.open(method, uri);
        if (method === 'POST' || method === 'PUT') {
            if (jsonParams) {
                jsonAsString = JSON.stringify(jsonParams) || '';
            }
        }

        client.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        if (token) {
            client.setRequestHeader('Authorization', `Token ${token}`);
        }

        client.send(jsonAsString);

        client.onload = function onload() {
            if (this.status >= 200 && this.status < 300) {
                resolve({ data: this.response });
            } else {
                reject(this.statusText);
            }
        };

        client.onerror = function onerror() {
            reject(this.statusText);
        };
    });

    return promise;
}
