import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../actions/Actions";

import Icon from "../icons/Icon.jsx";

class NumberOfPeople extends Component {
  increasePassengers() {
    this.props.actions.increasePassengers();
  }

  decreasePassengers() {
    this.props.actions.decreasePassengers();
  }

  render() {
    return (
      <div className="number-of-people__container">
        <div>Number of people travelling</div>
        <div className="number-of-people">
          <div
            className="number-of-people__icon arrow toggle"
            onClick={this.decreasePassengers.bind(this)}
          >
            <Icon icon="arrow-left" size="30px" />
          </div>
          <span className="number-of-people__actual-number">
            {this.props.numberOfPassengers}
          </span>
          <div
            className="number-of-people__icon arrow toggle"
            onClick={this.increasePassengers.bind(this)}
          >
            <Icon icon="arrow-right" size="30px" />
          </div>
        </div>
      </div>
    );
  }
}

NumberOfPeople.propTypes = {
  numberOfPassengers: React.PropTypes.number,
  actions: React.PropTypes.object
};

function mapState(state) {
  return {
    numberOfPassengers: state.form.numberOfPassengers
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatch
)(NumberOfPeople);
