import * as Actions from "./../constants/actions";

const initialState = {
  loading: false,
  results: {},
  error: null
};

export default function reducer(state = initialState, action = {}) {
  if (action.type === Actions.GET_FARES_STARTED) {
    return {
      ...state,
      loading: true,
      error: null,
      results: {}
    };
  }

  if (action.type === Actions.GET_FARES_SUCCESS) {
    const data = action.response;

    return {
      ...state,
      loading: false,
      error: null,
      results: {
        uberTotalFare: data.journeyInfo.uber.totalFare,
        tflTotalFare: data.journeyInfo.tfl.totalFare,
        uber: data.journeyInfo.uber,
        tfl: data.journeyInfo.tfl
      }
    };
  }

  if (action.type === Actions.GET_FARES_ERROR) {
    return {
      ...state,
      loading: false,
      error: action.error,
      results: {}
    };
  }

  return state;
}
