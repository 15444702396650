import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../actions/Actions";

import Header from "../components/Header.jsx";
import CustomGeoSuggest from "../components/CustomGeoSuggest.jsx";
import NumberOfPeople from "../components/NumberOfPeople.jsx";

import "../styles/main.scss";

class AppContainer extends Component {
  render() {
    return (
      <div className="container">
        <Header />
        <div className="form">
          <NumberOfPeople />

          <CustomGeoSuggest
            type="from"
            initialValue={this.props.from.label}
            showCurrentLocation={true}
          />
          <CustomGeoSuggest type="to" initialValue={this.props.to.label} />

          <button
            className="button-primary push-up-10 semi-bold"
            onClick={this.getPrices.bind(this)}
          >
            Compare
          </button>
          <div className="form__question">Uber vs Public Transport</div>
        </div>

        <div className="footer">
          <div className="push-down-10">
            <a href="mailto:hostmaster@tuber.london">Contact</a>
          </div>
        </div>
      </div>
    );
  }

  // deprecated until apps are back live
  renderPlayStoreLink() {
    return (
      <div className="store-link">
        <a
          href="https://play.google.com/store/apps/details?id=com.jvmartins.tuber&
                        pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
          />
        </a>
      </div>
    );
  }

  getPrices() {
    if (
      this.props.from &&
      this.props.from.latitude &&
      this.props.from.longitude &&
      (this.props.to && this.props.to.latitude && this.props.to.longitude)
    ) {
      this.props.actions.getFares({
        start: {
          latitude: this.props.from.latitude,
          longitude: this.props.from.longitude
        },
        end: {
          latitude: this.props.to.latitude,
          longitude: this.props.to.longitude
        },
        numberOfPassengers: this.props.numberOfPassengers
      });
    }
  }
}

function mapState(state) {
  return {
    numberOfPassengers: state.form.numberOfPassengers,
    from: state.form.from,
    to: state.form.to
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

AppContainer.propTypes = {
  numberOfPassengers: React.PropTypes.number,
  from: React.PropTypes.object,
  to: React.PropTypes.object,
  actions: React.PropTypes.object
};

export default connect(
  mapState,
  mapDispatch
)(AppContainer);
