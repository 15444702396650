// eslint-ignore
import ajaxHandler from "./AjaxHandler";

export default class TuberService {
  getPrices(params) {
    const url = "https://dwcojk9fi0.execute-api.eu-west-1.amazonaws.com/prod/";
    // const url = "http://localhost:3333/";
    return this.api(url).post(params);
  }

  api(url) {
    return {
      post: params => ajaxHandler("POST", url, params)
    };
  }
}
