import { hashHistory } from "react-router";

import * as Actions from "./../constants/actions";

import TuberService from "../services/TuberService";

const tuberService = new TuberService();

export function increasePassengers() {
  return {
    type: Actions.INCREASE_NUMBER_OF_PASSENGERS
  };
}

export function decreasePassengers() {
  return {
    type: Actions.DECREASE_NUMBER_OF_PASSENGERS
  };
}

export function updateFromLocation(from) {
  return {
    type: Actions.UPDATE_FROM_LOCATION,
    from
  };
}

export function updateToLocation(to) {
  return {
    type: Actions.UPDATE_TO_LOCATION,
    to
  };
}

export function getFares(params) {
  return dispatch => {
    dispatch({ type: Actions.GET_FARES_STARTED });

    hashHistory.push("/results");

    tuberService
      .getPrices(params)
      .then(response => {
        const data = JSON.parse(response.data);
        const r = data.body ? data.body : data;
        dispatch({ type: Actions.GET_FARES_SUCCESS, response: r });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: Actions.GET_FARES_ERROR,
          error: error || "Unexpected"
        });
      });
  };
}
