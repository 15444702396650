import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ajaxHandler from '../services/AjaxHandler';

import CustomGeoSuggest from '../components/CustomGeoSuggest.jsx';
import Icon from '../icons/Icon.jsx';
import * as Actions from '../actions/Actions';

import '../styles/main.scss';

import CityMapperLogo from '../images/citymapperlogo.png';
import UberLogo from '../images/uberlogo.png';

class Results extends Component {
    goToCityMapper() {
        window.location = `https://citymapper.com/directions?startcoord=${this.props.from.latitude}` +
            `%2C${this.props.from.longitude}&endcoord=${this.props.to.latitude}%2C${this.props.to.longitude}`;
    }

    goToUber() {
        const clientId = 'tGMHgS2tFH10v4zPQ-T9H2xsxPXKpvxL';
        window.location = `https://m.uber.com/ul/?client_id=${clientId}&action=setPickup` +
            `&pickup[latitude]=${this.props.from.latitude}` +
            `&pickup[longitude]=${this.props.from.longitude}` +
            `&dropoff[latitude]=${this.props.to.latitude}` +
            `&dropoff[longitude]=${this.props.to.longitude}`;
    }

    renderETA(commute) {
        if (!this.props.results || !this.props.results[commute].eta) {
            return null;
        }

        const className = commute === 'uber' ? 'eta__uber' : 'eta__city-mapper';
        return (
            <div className={ `eta ${className}` }>
                <span className="line">ETA</span>
                { this.props.results['tfl'].eta /*{ this.props.results[commute].eta }*/}
            </div>
        );
    }

    renderCityMapperButton(text) {
        return (
            <div className="action-button__container">
                <div className="city-mapper" onClick={ this.goToCityMapper.bind(this) }>
                    <div className="action-button__image">
                        <img src={ CityMapperLogo } />
                    </div>
                    <div className="action-button__text">
                        { text }
                    </div>
                </div>
                { this.renderETA('tfl') }
            </div>
        );
    }

    renderUberButton(text) {
        return (
            <div className="action-button__container">
                <div className="uber" onClick={ this.goToUber.bind(this) }>
                    <div className="action-button__image">
                        <img src={ UberLogo } />
                    </div>
                    <div className="action-button__text">
                        { text }
                    </div>
                </div>
                { this.renderETA('uber') }
            </div>
        );
    }

    renderShareButton() {
        if (this.props.results.uber.optimised.uberX + this.props.results.uber.optimised.uberXL > 1) {
            return (
                <div className="share-button__container">
                    <div>
                        You will need
                        <span className="semi-bold">
                            { this.props.results.uber.optimised.uberX > 0 ?
                                ` ${this.props.results.uber.optimised.uberX} UberX` : '' }
                        </span>
                        { this.props.results.uber.optimised.uberX > 0 &&
                            this.props.results.uber.optimised.uberXL > 0 ? ' and' : '' }
                        <span className="semi-bold">
                            { this.props.results.uber.optimised.uberXL > 0 ?
                            ` ${this.props.results.uber.optimised.uberXL} UberXL` : '' }
                        </span>
                        <span className="line">Share with your group</span>
                    </div>
                    <div className="share-link">
                        <div className="share-button__button toggle" onTouchEnd={ this.onClickShareLink.bind(this) }>
                            <Icon icon="share" size="35px" style={ { fill: '#3c5a72' } }/>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    onClickWhatsAppLink() {
        const clientId = 'tGMHgS2tFH10v4zPQ-T9H2xsxPXKpvxL';
        const longUrl = `https://m.uber.com/ul/?client_id=${clientId}&action=setPickup` +
            `&pickup[latitude]=${this.props.from.latitude}` +
            `&pickup[longitude]=${this.props.from.longitude}` +
            `&dropoff[latitude]=${this.props.to.latitude}` +
            `&dropoff[longitude]=${this.props.to.longitude}`;

        const url = 'https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyAs-KxAu9xzVzYEpUZxExDfwsx_8Q-_CJo';
        ajaxHandler('POST', url, { longUrl }).then((response) => {
            const data = JSON.parse(response.data);
            const encodedUrl = encodeURI(data.id);
            window.location = `whatsapp://send?text=Request another Uber for us! ${encodedUrl}`;
        });
    }

    onClickShareLink() {
        const clientId = 'tGMHgS2tFH10v4zPQ-T9H2xsxPXKpvxL';
        const longUrl = `https://m.uber.com/ul/?client_id=${clientId}&action=setPickup` +
            `&pickup[latitude]=${this.props.from.latitude}` +
            `&pickup[longitude]=${this.props.from.longitude}` +
            `&dropoff[latitude]=${this.props.to.latitude}` +
            `&dropoff[longitude]=${this.props.to.longitude}`;

        const url = 'https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyCdNdfJABkPEh1D3fkGxaE9N4yTN3JEL3o';
        ajaxHandler('POST', url, { longUrl }).then((response) => {
            const data = JSON.parse(response.data);
            if (window.socialmessage) {
                const message = {
                    text: 'Request another Uber for us!',
                    url: data.id,
                };
                window.socialmessage.send(message);
            }
        });
    }

    renderSubMessage() {
        return null;
    }

    renderPriceMessage(diff) {
        if (diff !== 0) {
            return <div className="table-cell">
                <div className="push-left-15">
                    It will cost
                    <span className="price">
                        &nbsp;£{ (Math.abs(diff) / this.props.numberOfPassengers).toFixed(2) }&nbsp;
                    </span>
                    { diff < 0 ? 'more' : 'less' }
                    { this.props.numberOfPassengers === 1 ? '' : ' per person' } to go
                    by <span className="semi-bold">Uber</span>
                </div>
            </div>;
        }

        return <div className="table-cell">
            <div className="push-left-15">
                Both are exactly the same price! It will
                cost <span className="price">£{ this.props.results.tflTotalFare }</span> in total
            </div>
        </div>;
    }

    renderTimeMessage(diff, timeDiff) {
        return <div className="table-cell">
            <span className="line push-left-15">
                { (diff < 0 && timeDiff > 0) || (diff > 0 && timeDiff < 0) ? 'But' : 'And' } will be
                { timeDiff === 0 ?
                    ' the same time' :
                    <span>
                        {/*TODO this is not accurate*/}
                        <span className="price">&nbsp;{ Math.abs(5) }&nbsp;</span>
                        minutes { timeDiff > 0 ? 'faster' : 'slower' }
                    </span> }
                { diff === 0 ?
                    <span className="line">
                        by <span className="price">Uber</span>
                    </span> : '' }
            </span>
        </div>;
    }

    renderMessage(diff, timeDiff) {
        return (
            <div>
                <div className="push-up-10">
                    <div className="table-cell">
                        <Icon icon="money" size="37px" style={ { fill: '#3C5A72' } }/>
                    </div>
                    { this.renderPriceMessage(diff) }
                </div>
                <div className="push-up-10">
                    <div className="table-cell">
                        <Icon icon="time" size="37px" style={ { fill: '#3C5A72' } }/>
                    </div>
                    { this.renderTimeMessage(diff, timeDiff) }
                </div>
            </div>
        );
    }

    goBack() {
        window.history.back();
    }

    renderPriceDetails() {
        if (this.props.loading) {
            return (
                <div className="results__loading">
                    <div>
                        Calculating fares for <span className="line">Uber vs. Public Transport</span>
                    </div>
                    <div className="results__loading__dots">
                        <span>&#8226;</span>
                        <span>&#8226;</span>
                        <span>&#8226;</span>
                    </div>
                </div>
            );
        }

        if (this.props.error) {
            return <div className="results__loading">
                Sorry, we can't process your request now.
                <div className="push-up-20">
                    Please make sure your addresses are inside London area
                </div>
            </div>;
        }

        if (!this.props.results.tflTotalFare || !this.props.results.uberTotalFare) {
            return null;
        }

        const diff = parseFloat(this.props.results.tflTotalFare) - parseFloat(this.props.results.uberTotalFare);
        const timeDiff = parseInt(this.props.results.tfl.duration) - parseInt(this.props.results.uber.x.duration);
        return (
            <div className="price-details">
                <div>
                    <div className="table-cell">
                        <Icon icon="group" size="37px" style={ { fill: '#3C5A72' } }/>
                    </div>
                    <div className="table-cell">
                        <span className="price push-left-15">{ this.props.numberOfPassengers }
                            { this.props.numberOfPassengers === 1 ? ' person' : ' people' }
                        </span>
                    </div>
                </div>

                <div className="message">
                    { this.renderMessage(diff, timeDiff) }
                </div>

                { this.renderSubMessage(diff, timeDiff) }

                <div className="text-center push-up-20">
                    { this.renderCityMapperButton('See directions on Citymapper') }
                    { this.renderUberButton('Get a ride with Uber') }
                    { this.renderShareButton() }
                </div>

                <div className="back-button" onTouchEnd={ this.goBack.bind(this) }>
                    <Icon icon="back" style={ { fill: '#3c5a72' } } size="23px"/>
                </div>
            </div>
        );
    }

    onLocationChange() {
        if ((this.props.from && this.props.from.latitude && this.props.from.longitude) &&
            (this.props.to && this.props.to.latitude && this.props.to.longitude)) {
            this.props.actions.getFares({
                start: {
                    latitude: this.props.from.latitude,
                    longitude: this.props.from.longitude,
                },
                end: {
                    latitude: this.props.to.latitude,
                    longitude: this.props.to.longitude,
                },
                numberOfPassengers: this.props.numberOfPassengers,
            });
        }
    }

    render() {
        return (
            <div className="results__container">
                <div className="results__search-bar">
                    <CustomGeoSuggest type="from"
                                      initialValue={ this.props.from.label }
                                      onSelect={ this.onLocationChange.bind(this) }
                                      showCurrentLocation={ true }/>
                    <CustomGeoSuggest type="to"
                                      onSelect={ this.onLocationChange.bind(this) }
                                      initialValue={ this.props.to.label }/>
                </div>
                { this.renderPriceDetails() }
            </div>
        );
    }
}

function mapState(state) {
    return {
        results: state.integration.results,
        error: state.integration.error,
        loading: state.integration.loading,
        numberOfPassengers: state.form.numberOfPassengers,
        from: state.form.from,
        to: state.form.to,
    };
}

function mapDispatch(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch),
    };
}

Results.propTypes = {
    loading: React.PropTypes.bool,
    results: React.PropTypes.object,
    error: React.PropTypes.node,
    numberOfPassengers: React.PropTypes.number,
    from: React.PropTypes.object,
    to: React.PropTypes.object,
    actions: React.PropTypes.object,
};

export default connect(mapState, mapDispatch)(Results);
