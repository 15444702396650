import React, { Component } from 'react';

import logo from '../images/logo.png';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="header__logo">
                    <img className="header__logo__image" src={ logo }/>
                    <h1 className="header__logo__type">TUBER</h1>
                </div>
                <h2 className="header__secondary">London</h2>
            </div>
        );
    }
}

export default Header;
