import React, { Component } from "react";
import GeoSuggest from "react-geosuggest";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "../actions/Actions";

import Icon from "../icons/Icon.jsx";

class CustomGeoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
      myLocationSelected: false,
      currentLocation: null,
      label: null
    };
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({
          ...this.state,
          currentLocation: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        });
      },
      () => {
        this.setState({
          ...this.state
        });
      }
    );

    this.setState({
      ...this.state,
      myLocationSelected: this.props.initialValue === "My Location"
    });
  }

  onFocus() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (
      this.props.showCurrentLocation &&
      navigator &&
      navigator.geolocation &&
      !this.state.currentLocation
    ) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            ...this.state,
            currentLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          });
        },
        () => {
          this.setState({
            ...this.state
          });
        }
      );
    }

    this.setState({ ...this.state, hasFocus: true });
  }

  onBlur() {
    this.setState({ ...this.state, hasFocus: false });
  }

  onSuggestSelect(suggest) {
    this.setState({
      ...this.state,
      hasFocus: false,
      myLocationSelected: false
    });

    if (this.props.type === "from") {
      this.props.actions.updateFromLocation({
        latitude: suggest.location.lat,
        longitude: suggest.location.lng,
        label: suggest.label
      });
    } else if (this.props.type === "to") {
      this.props.actions.updateToLocation({
        latitude: suggest.location.lat,
        longitude: suggest.location.lng,
        label: suggest.label
      });
    }

    if (this.props.onSelect) {
      this.props.onSelect();
    }

    this.forceUpdate();
  }

  clear() {
    const geoSuggest = this.refs.geoSuggest.refs.input;
    if (geoSuggest && !geoSuggest.refs.input.value) {
      this.setState({ ...this.state, hasFocus: false });
    } else {
      this.setState({ ...this.state, myLocationSelected: false, label: null });
      this.refs.geoSuggest.clear();
    }
  }

  selectCurrentLocation() {
    const label = "My Location";

    this.setState({
      ...this.state,
      hasFocus: false,
      myLocationSelected: true,
      label
    });
    this.refs.geoSuggest.update(label);

    if (!this.state.currentLocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            ...this.state,
            hasFocus: false,
            myLocationSelected: true,
            label,
            currentLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          });

          if (this.props.type === "from") {
            this.props.actions.updateFromLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              label
            });
          } else if (this.props.type === "to") {
            this.props.actions.updateToLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              label
            });
          }
        },
        () => {
          this.setState({
            ...this.state
          });
        }
      );
    } else if (this.props.type === "from") {
      this.props.actions.updateFromLocation({
        latitude: this.state.currentLocation.latitude,
        longitude: this.state.currentLocation.longitude,
        label
      });
    } else if (this.props.type === "to") {
      this.props.actions.updateToLocation({
        latitude: this.state.currentLocation.latitude,
        longitude: this.state.currentLocation.longitude,
        label
      });
    }
  }

  render() {
    let className = `geosuggest__container ${
      this.state.hasFocus ? "geosuggest__container--focused" : ""
    }`;

    if (this.props.showCurrentLocation && this.state.currentLocation) {
      className += " geosuggest__display-current";
    }

    if (this.state.myLocationSelected) {
      className += " geosuggest__my-location-selected";
    }

    const labelValue = this.state.label
      ? this.state.label
      : this.props.initialValue;
    const londonCentre = new google.maps.LatLng(51.5006708, -0.1248292);

    return (
      <div>
        <div className={className}>
          <GeoSuggest
            country="uk"
            ref="geoSuggest"
            placeholder={this.props.type.toUpperCase()}
            onFocus={this.onFocus.bind(this)}
            initialValue={labelValue}
            location={londonCentre}
            radius={20000}
            onSuggestSelect={this.onSuggestSelect.bind(this)}
          />

          {this.props.showCurrentLocation &&
          this.state.hasFocus &&
          this.state.currentLocation ? (
            <div
              className="my-location-option"
              onClick={this.selectCurrentLocation.bind(this)}
            >
              <div className="icon">
                <Icon icon="my-location" size="22px" />
              </div>
              <div className="option">My location</div>
            </div>
          ) : null}

          <div className="geosuggest__clear-button__container">
            <button
              className="geosuggest__clear-button"
              onClick={this.clear.bind(this)}
            >
              X
            </button>
          </div>
        </div>
        {this.state.hasFocus ? (
          <div className="full-width-layer" onClick={this.onBlur.bind(this)} />
        ) : null}
      </div>
    );
  }
}

CustomGeoSuggest.propTypes = {
  type: React.PropTypes.string,
  initialValue: React.PropTypes.string,
  showCurrentLocation: React.PropTypes.bool,

  onSelect: React.PropTypes.func,

  to: React.PropTypes.object,
  from: React.PropTypes.object,
  actions: React.PropTypes.object
};

function mapState(state) {
  return {
    from: state.form.from,
    to: state.form.to
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatch
)(CustomGeoSuggest);
